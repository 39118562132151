.image_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 10px;
    height: 60px;
    border: 1px solid #ddd;
    border-radius: 10px;
  }
  
  .image_wrapper img {
    width: 100px;
    height: 60px;
    object-fit: contain;
  }
  
  /* .title{
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0;
          gap: 0px;
       } */
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 0px;
  }
  
  .title .main_typography {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    /* line-height: 100%; */
    text-align: center;
    letter-spacing: 1px;
    text-transform: capitalize;
    color: #003c5d;
  }
  
  title .text {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 20px;
    text-align: center;
    color: #4b5563;
  }
  
  .title2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    /* gap: 48px; */
    animation: moveUp 4s ease-in-out infinite;
  }
  
  @keyframes moveUp {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    85% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
      animation-play-state: paused;
      animation-delay: 20s;
    }
  }
  
  .title2 .typography {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    text-align: center;
    /* letter-spacing: 1px; */
    text-transform: capitalize;
    color: #003c5d;
  }
  
  .title2 .typo_text {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 20px;
    text-align: center;
    color: #4b5563;
    margin-bottom: 0.5rem;
  }
  
  /* for all screen  */
  @media (max-width: 375px) {
    .title .main_typography {
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      font-size: 2rem;
      /* line-height: 0.5rem; */
      line-spacing: 1;
    }
  
    .title .text {
     
      font-weight: 200;
      font-size: 0.7rem;
      line-height: 0;
    }
  
    .title2 .typography {
      
      font-weight: 700;
          font-size: 2rem;
          line-height: 100%;
    
    }
  
    .title2 .typo_text {
      
      font-weight: 300;
      font-size: 0.5rem;
      line-height: 1rem;
      
    }
  }
  
  @media (max-width: 425px) {
    .title .main_typography {
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      font-size: 2rem;
      line-height: 0.5rem;
      line-spacing: 0;
    }
    .title2 .typography {
      
      font-weight: 700;
          font-size: 2rem;
          line-height: 100%;
    
    }
  
    .title2 .typo_text {
      
      font-weight: 300;
      font-size: 0.5rem;
      line-height: 1rem;
      
    }
   
  }
  
  @media (max-width: 768px) {
    .title .main_typography {
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      font-size: 2rem;
      line-height: 0.5rem;
      line-spacing: 1;
    }
  
    .title .text {
     
      font-weight: 200;
      font-size: 0.8rem;
      line-height: 1;
    }
  
    .title2 .typography {
      
      font-weight: 700;
          font-size: 2rem;
          line-height: 100%;
    
    }
    .title2 .typo_text {
      
      font-weight: 300;
      font-size: 0.8rem;
      line-height: 1rem;
      
    }
  }
  
  @media (max-width: 1024px) {
    /* .title .main_typography {
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      font-size: 3rem;
      line-height: 1rem;
      line-spacing: 1;
    } */
  
    .title2 .typography {
      
      font-weight: 700;
          font-size: 2rem;
          line-height: 100%;
    
    }
    .title2 .typo_text {
      
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.1rem;
      margin: 0rem 1rem 0rem 1rem;
      
    }
  }
  
  @media (max-width: 1440px) {
    /* .title .main_typography {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      font-size: 5rem;
      line-spacing: 1;
    } */
  }
  