.home_hero_wrapper_2 {
    padding-top: 4rem;
  }
  
  .in-depth-audit-report {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 35px 16px 17px 17px;
    justify-content: center;
    align-items: center;
    background: radial-gradient(
      62.13% 119.61% at 52.44% -14.71%,
      #1e5c7e 0%,
      #003c5d 81.8%
    );
    border-radius: 8px;
    position: relative;
  }
  .in-depth-audit-report p {
    color: rgba(255, 255, 255, 0.85);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .in-depth-audit-report .icon_container {
    width: 62px;
    height: 62px;
    background: #165375;
    border: 3px solid white;
    grid: grid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -34px;
  }
  
  .batch_2_tarif {
  background: linear-gradient(to right,#007DC1,#4AABE0);
    /* padding: 4px 0px 5px 18px; */
    padding: 12px 0px 1px 18px;
    border-radius: 8px;
    flex-shrink: 0;
    width: 100%;
    position: relative;
  }
  .batch_2_tarif .icon_box {
    width: 62px;
    height: 62px;
    background: #379fd9;
    border: 3px solid white;
    grid: grid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 99%;
    transform: translateX(-99%);
    top: -34px;
  }
  
  .home_hero_item_2 {
    margin-top: 4rem;
  }
  
  .hero_section_item_4 {
    margin-top: 4rem;
    padding: 20px;
    font-size: .9rem;
  }
  
  .home_hero_women {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  @media (min-width: 769px) {
    /* Medium screens */
    .heading-1 {
      font-size: 48px;
      line-height: 110%; /* 52.8px */
    }
    .home_hero_women {
      display: none;
    }
    .batch_2_tarif .icon_box {
      width: 62px;
      height: 62px;
      background: #379fd9;
      border: 3px solid white;
      grid: grid;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 110%;
      transform: translateX(-110%);
      top: -34px;
    }
  }
  
  @media (min-width: 992px) {
    /* Extra-large screens */
    .home_hero_wrapper_2 {
      padding-top: 8rem;
    }
    .home_hero_item_2 {
      margin-top: 6rem;
    }
    .in-depth-audit-report {
      margin-left: 4rem;
    }
    .hero_section_item_4 {
      margin-top: 6rem;
    }
    .in-depth-audit-report {
      max-width: 308px;
    }
    .batch_2_tarif {
      max-width: 320px;
    }
    .home_hero_women {
      display: block;
      width: 320px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 150px;
    }
    .home_hero_item_2{
      margin-top: 3rem;
    }
    .home_hero_wrapper_2 {
      padding-top: 4rem;
    }
    .hero_section_item_4 {
      margin-top: 3rem;
    }
    .line_hero_section_bottom {
      display: block;
    }
  }
  
  
  @media (min-width: 1200px) {
    /* Extra-large screens */
    .heading-1 {
      font-size: 58px;
      line-height: 110%; /* 63.8px */
    }
    .line_hero_section_bottom {
      display: block;
    }
  
    .hero_button_container {
      max-width: 530px;
      margin: 0 auto;
      display: block;
      width: 100%;
    }
    .home_hero_women {
      display: block;
      width: 427px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
    }
    .home_hero_item_2{
      margin-top: 3rem;
    }
    .home_hero_wrapper_2 {
      padding-top: 4rem;
    }
    .hero_section_item_4 {
      margin-top: 3rem;
    }
  }
/*   
  @media (min-width: 1201px) and (max-width: 1400px) {
    .home_hero_women {
      display: block;
      width: 527px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 75px;
    }
    .home_hero_item_2{
      margin-top: 3rem;
    }
    .home_hero_wrapper_2 {
      padding-top: 4rem;
    }
    .hero_section_item_4 {
      margin-top: 3rem;
    }
  } */
  @media (min-width: 1398px) and (max-width: 1920px) {
    .home_hero_women {
      display: block;
      width: 527px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 15px;
    }
    .home_hero_item_2{
      margin-top: 3rem;
    }
    .home_hero_wrapper_2 {
      padding-top: 4rem;
    }
    .hero_section_item_4 {
      margin-top: 3rem;
    }
    .line_hero_section_bottom {
      margin-top: 4rem;
    }
  }
  