/* Custom CSS */
.bg-custom-secondary {
    background-color: #8dc63f; /* Replace with actual color */
  }
  
  .rounded-10 {
    border-radius: 10px;
  }
  
  .custom-card {
    box-shadow: 4px 4px 55px rgba(0, 0, 0, 0.12);
    position: relative;
    overflow: visible;
    padding: 1rem;
  }
  .svg-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  }
  .icon-badge {
    width: 62px;
    height: 62px;
    border: 2px solid #8dc63f;
    border-radius: 50%;
    background: white;
    position: absolute;
    top: -.5rem;
    right: -.7rem;
    display: grid;
    place-items: center;
  }
  
  .max-width-paragraph {
    max-width: 55%;
  }
  
  @media (max-width: 1200px) {
    .max-width-paragraph {
      max-width: 75%;
    }
  }
  
  /* Add other media queries and custom styles as needed */