.report_details_result_box_flex{
    display: flex;
}
.report_details_result_box{
  width: 50%;
}
.report_detail_first{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(207, 205, 205);
}
.report_detail_first_btncontainer{
    display: flex;
    gap: 1rem;
    padding-right: 1rem;
}
.report_question_summary{
    padding: .5rem 1rem;
    background-color: rgb(240,250,255);
}
.report_question_summary p{
    font-size: .9rem;
}
.report_question_attachment{
    margin-top: 1rem;
}
.report_question_attachment_imgcontainer{
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
}
.attachment-container{
    border: 2px solid transparent;
}
.attachment-container:hover{
    border: 2px solid black;
}
.report_img_container_img{
    height: 180px;
    width: 180px;
 object-fit: cover;
 /* border: 1px solid red; */
}
.larger-image-container .larger-image{
    height: 400px;
    width:90%;
    object-fit: contain;
}
.larger-image-container_closebtn{
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    border-bottom: 1px solid rgb(193, 190, 190);
}
.larger-image-container_imgbox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
}
.semi_donut{
  --percentage: 0;
  --fill: #ff0;
  width: 300px;
  height: 150px;
  position: relative;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  overflow: hidden;
  color: var(--fill);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing : border-box;
  margin: 25px;
  margin-top: 4rem;
}
.semi_donut::after{
    content: '';
    width: 300px;
    height: 300px;
    border:50px solid;
    border-color : rgba(0,0,0,0.15) rgba(0,0,0,0.15) var(--fill) var(--fill);
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 0;
    box-sizing : border-box;
    transform: rotate( calc( 1deg * ( -45 + var(--percentage) * 1.8 ) ) );
    animation : fillAnimation 1s ease-in;
  }
  @keyframes fillAnimation{
    0%{transform : rotate(-45deg);}
    50%{transform: rotate(135deg);}
  }

  
  @media (max-width: 1440px) {
    .report_details_flexbox{
        flex-direction: column;
    }
    .report_details_result_box{
        margin-top: 2rem;
        width: 100%;
      }
}
@media (max-width: 750px) {
    .report_detail_first {
      flex-direction: column;
      gap: 1rem;
    }
  .report_details_result_box{
    margin-top: 2rem;
  }
    .report_detail_first_btncontainer {
      padding-right: 0; 
    }
    .report_details_result_box_flex{
        flex-direction: column;
        align-items: center;
    }
    .report_colorful_chart{
        width: 100%;
    }
    .report_question_table tr,.report_question_table td{
      font-size: .8rem;
    }
  }
  