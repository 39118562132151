* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .dropdown-toggle::after {
    display: none !important;
  }
  
  body {
    background: var(--Default-White, #fff);
    color: var(--Neutral-Neutral-600, #4b5563);
  }
  
  p {
    margin: 0;
  }
  
  .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;
    border-radius: 5px;
    gap: 8px;
    font-size: 16px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    background: transparent;
    border: 2px solid transparent;
  }
  .w-full {
    width: 100% !important;
  }
  .button-outline:hover {
    border: 2px solid var(--Neutral-Neutral-600, #007dc1);
    background: var(--Primary-Primary, #007dc1);
    color: white;
  }
  
  .button-outline {
    border: 2px solid var(--Neutral-Neutral-600, #4b5563);
    color: var(--Neutral-Neutral-600, #4b5563);
  }
  
  .button-outline-2 {
    border: 2px solid #007dc1;
    color: #007dc1;
  }
  .button-outline-2:hover {
    border: 2px solid var(--Neutral-Neutral-600, #007dc1);
    background: var(--Primary-Primary, #007dc1);
    color: white;
  }
  
  .button-primary {
    background: var(--Primary-Primary, #007dc1);
    color: white;
  }
  
  .button-primary:hover {
    background: transparent;
    color: #007dc1;
    border: 2px solid var(--Neutral-Neutral-600, #007dc1);
  }
  
  .section-title {
    font-size: 40px;
    color: #003c5d;
    letter-spacing: 1px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
  }
  
  .pricing_content_wrapper {
    margin-top: 1rem;
  }
  
  .pricing_detail_card {
    background: rgba(215, 241, 255, 0.12);
    border-radius: 10px;
    display: flex;
    box-shadow: 4px 4px 55px 0px rgba(0, 0, 0, 0.1);
    flex-direction: column;
  }
  .pricing_detail_card_left {
    background: white;
    padding: 20px;
    flex-basis: 100%;
    box-shadow: 4px 4px 55px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  .pricing_detail_card_left_title {
    font-size: 28px;
    font-weight: 500;
    color: #003c5d;
  }
  .pricing_detail_card_right {
    padding: 20px;
    flex-basis: 100%;
  }
  .pricing_detail_card_left_des {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #4b5563;
  }
  .pricing_detail_card_left_price {
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    color: #003c5d;
  }
  .pricing_detail_card_left_price span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    color: #858ba0;
  }
  .pricing_detail_card_left_gst {
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    color: #4b5563;
    font-family: "Roboto", sans-serif;
  }
  .pricing_detail_card_left_city_count {
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    color: #003c5d;
  }
  .pricing_detail_card_left_city_count > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    color: #858ba0;
  }
  
  .view_package_details-btn {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-decoration-line: underline;
    color: var(--Primary-Primary600, #11abff);
    font-family: "Roboto", sans-serif;
    text-align: center;
    display: block;
  }
  
  .pricing_detail_card_right {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .pricing_detail_card_right_title {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 111.111% */
    color: #003c5d;
  }
  h2,
  h1,
  h4,
  h5 {
    font-family: "Roboto", sans-serif;
  }
  .heading-1 {
    font-weight: bold;
    color: #00527e;
    font-size: 36px;
    line-height: 110%; /* 39.6px */
  }
  
  .heading-3 {
    font-size: 24px;
    font-weight: 900;
    color: #fafafa;
    text-transform: capitalize;
  }
  .heading-suggested-product{
    font-size: 20px;
    font-weight: 600;
    color: #fafafa;
    text-transform: capitalize;
  }
  .pera {
    font-size: 15px;
    font-weight: 400;
  }
  
  .serve-text-style {
    color: #4b5563;
    font-family: "Lato", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: inline-flex;
    flex: 1;
    align-items: flex-start;
  }
  span.bullet {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #4b5563;
    margin-top: 6px;
  }
  
  .serve_type_category {
    color: var(--Primary-Primary, #007dc1);
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
  }
  
  .serve-type-style {
    padding: 10px;
    background: #fafafa;
    font-size: 21px;
    font-weight: 700;
    color: #003c5d;
    text-align: center;
  }
  .g_text {
    background: linear-gradient(90deg, #8dc63f 54.9%, #007dc1 63.72%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .how_it_works_items {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }
  
  .recommend_reading_container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin-top: 2rem;
  }
  
  .reading_card {
    transform: scale(1.03);
    background-color: red;
    padding: var(--16, 16px);
    gap: var(--16, 16px);
  }
  
  .reading_card_img {
    height: 216px;
    object-fit: cover;
    border-radius: 6px;
    width: 100%;
  }
  
  .reading_card_title,.reading_card_title-suggested-product {
    color: #003c5d;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 18px;
    margin-top: 1rem;
  }
.reading_card_title-suggested-product{
  font-size: 14px;
}
  .reading_card_description {
    color: #9ca3af;
    font-size: 14px;
    font-weight: 600;
    max-width: 262.707px;
  }
  .card-body {
    width: 100% !important;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .reading_card_brand_logo {
    width: 108px;
    height: 31px;
    object-fit: contain;
  }
  
  .price_text {
    font-size: 32px;
    font-weight: 800;
    color: #003c5d;
  }
  .price_text > span {
    color: #858ba0;
    font-size: 14px;
    font-weight: 500;
  }
  .text-16 {
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    color: var(--Neutral-Neutral-600, #4b5563);
    font-family: "Roboto", sans-serif;
  }
  
  .benifit_card {
    min-height: 240px;
  }
  
  .servey_card {
    box-shadow: 4px 4px 55px 0px rgba(0, 0, 0, 0.12);
    background: #fff;
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    margin-top: 116px;
    gap: 25px;
    justify-content: space-between;
  }
  
  .servey_card_img {
    width: 94%;
    display: block;
    margin: 0 auto;
    height: 154px;
    object-fit: cover;
    border-radius: 8px;
    position: relative;
    z-index: 2;
    margin-top: -183px;
  }
  
  .servey_card_wrapper {
    display: grid;
    gap: 2rem;
    margin-top: 3rem;
  }
  @media (min-width: 576px) {
    /* Small screens */
    .heading-1 {
      font-size: 42px;
      line-height: 110%; /* 46.2px */
    }
    .how_it_works_items {
      grid-template-columns: repeat(2, 1fr);
    }
    .recommend_reading_container {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .servey_card_wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 768px) {
    /* Medium screens */
    .heading-1 {
      font-size: 48px;
      line-height: 110%; /* 52.8px */
    }
    .pricing_detail_card {
      flex-direction: row;
    }
    .pricing_detail_card_left {
      background: white;
      padding: 20px;
      flex-basis: 55%;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  
    .pricing_detail_card_right {
      padding: 20px;
      flex-basis: 45%;
    }
  }
  
  @media (min-width: 992px) {
    /* Large screens */
    .heading-1 {
      font-size: 54px;
      line-height: 110%; /* 59.4px */
    }
    .how_it_works_items {
      grid-template-columns: repeat(5, 1fr);
    }
    .pera {
      font-size: 15px;
    }
    .heading-4 {
      font-size: 24px;
      font-weight: 900;
      color: #fafafa;
      text-transform: capitalize;
    }
    .recommend_reading_container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (min-width: 1200px) {
    /* Extra-large screens */
    .heading-1 {
      font-size: 58px;
      line-height: 110%; /* 63.8px */
    }
    .pricing_content_wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .pricing_middle_divider {
      position: absolute;
      height: 100%;
      width: 2px;
      background: linear-gradient(90deg, #fff 0%, #003c5d 50%, #fff 100%);
      left: 50%;
      transform: translateX(-50%);
    }
    .benifit_card {
      min-height: 240px;
    }
    .servey_card_wrapper {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  