.FloorWalkMarketplace_wrapper {
    background: linear-gradient(111deg, #007dc1 0.54%, #003b5b 93.47%);
    padding: 80px 0px;
  }

  .marketplace_item_container {
    display: grid;
    gap: 1.5rem;
    max-width: 1016px;
    margin: 0 auto;
    padding-top: 40px;
  }
  
  .marketplace_item {
    padding: 20px 40px;
    box-shadow: 0px 12px 56px 0px rgba(6, 28, 61, 0.12);
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: white;
    border-radius: 10px;
  }
  .marketplace_item .text-lg {
    color: #9ca3af;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
  }
  .marletplace_item_img{
    width: 100%;
    object-fit: cover;
    /* aspect-ratio: 2/2; */
    height: 200px;
  }
  .learn_more_btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #007dc1;
    font-size: 16px;
    font-weight: 500;
    height: 32px;
    border: none;
    outline: none;
    border-bottom: 2px solid #0b63e5;
    background: #fafafa;
  }
  
  @media (min-width: 992px) {
    /* Large screens and up */
    .marketplace_item_container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 991px) {
    /* Small screens and below */
    .marketplace_item_container {
      grid-template-columns: 1fr;
    }
  }
  